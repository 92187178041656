import Cookies from "js-cookie";
import { clearStore, unsubscribe } from "../store/store";
import { random } from "lodash";
import { cookieStrings, localStorageStrings } from "../models";

type ThirdPartyState = {
    stateId: string;
    platform: string;
    stage?: string;
};

export const domainName = new URL(document.URL).hostname;
export const pseudoLoc = process.env.REACT_APP_PSEUDO_LOC === "true";
export const reactAppEnv = process.env.REACT_APP_ENV;
export const isProd =
    domainName === "artists.amazon.com" && reactAppEnv !== "local";

const japanUiCustomizationKey = "amzn_alps_ziggy_web_prod_jp_jp_ui_new";
const rowUiCustomizationKey = "amzn_ziggy_ui_new";
const getUiCustomization = (isJapan: boolean): string => {
    if (isJapan) {
        return japanUiCustomizationKey;
    } else {
        return rowUiCustomizationKey;
    }
};

const baseSignOutUrl = (assoc: string, isJapan: boolean) =>
    "https://www.amazon.com/ap/signin?openid.ns=" +
    "http%3a%2f%2fspecs.openid.net%2fauth%2f2.0" +
    "&openid.mode=logout" +
    "&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select" +
    "&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select" +
    "&pageId=" +
    getUiCustomization(isJapan) +
    "&language=en_US" +
    "&openid.return_to=https://" +
    domainName +
    "&openid.assoc_handle=" +
    assoc;

export const goToSignInURl = (isJapan: boolean, locale: string) => {
    clearAmazonLogInCookies();
    clearStore();
    localStorage.removeItem(localStorageStrings.csrf);
    window.location.href = generateSignInUrl(isJapan, locale);
};

const devAssociationHandle = "alps_ziggy_web_global_us";
const prodAssociationHandle = "alps_ziggy_web_global_us";

const japanAssociationHandle = "amzn_alps_ziggy_web_prod_jp_jp";
const PLATFORM = "web";
const STATE_LIMIT = 99999;

const getAssociation = (isJapan: boolean): string => {
    if (isJapan) {
        return japanAssociationHandle;
    } else if (isProd) {
        return prodAssociationHandle;
    } else {
        return devAssociationHandle;
    }
};

export const signOutUrl = (isJapan: boolean): string => {
    return baseSignOutUrl(getAssociation(isJapan), isJapan);
};

export const generateSignInUrl = (isJapan: boolean, locale: string) => {
    return (
        "https://amazon.com/ap/signin?openid.ns=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0" +
        "&openid.mode=checkid_setup" +
        "&openid.claimed_id=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select" +
        "&openid.identity=http%3a%2f%2fspecs.openid.net%2fauth%2f2.0%2fidentifier_select" +
        "&openid.pape.max_auth_age=1200" +
        "&pageId=" +
        getUiCustomization(isJapan) +
        "&language=" +
        locale +
        "&openid.assoc_handle=" +
        getAssociation(isJapan) +
        "&openid.return_to=https://auth.artists.amazon.com/V1/web/register?path=https://" +
        domainName
    );
};

export const domainNamePrefix = domainName.split(".")[0];

export const stageMap = new Map<string, string>([
    ["prototype", "devo"],
    ["beta", "beta"],
    ["artists", "prod"],
    ["staging1", "staging1"],
    ["staging2", "staging2"],
]);

export const stage = stageMap.has(domainNamePrefix)
    ? stageMap.get(domainNamePrefix)
    : "prod";

export const getRandomStateVal = (): number => {
    return Math.ceil(Math.random() * STATE_LIMIT);
};

export const generateState = (stateId?: string): ThirdPartyState => {
    return {
        stateId: stateId ?? getRandomStateVal().toString(),
        platform: PLATFORM,
        stage: stage,
    };
};

export type serviceEndpoints = {
    artistControl: string;
    userManagement: string;
    reporting: string;
    catalog: string;
    teamManagement: string;
    inviteMangement: string;
    outageStatus: string;
    merch: string;
    clientMetrics: string;
    oAuth: string;
};

export type thirdPartyEndpoionts = {
    facebookUrl: (state: string, clientId: string) => string;
    instagramUrl: (state: string, clientId: string) => string;
    cdBabyUrl: (state: string, clientId: string) => string;
    distrokidUrl: (state: string, clientId: string) => string;
    tunecoreUrl: (state: string, clientId: string) => string;
    tunecoreJPUrl: (state: string, clientId: string) => string;
    twitterUrl: (state: string, clientId: string) => string;
    merchOnDemandUrl: () => string;
};

const prodEndpoints: serviceEndpoints = {
    artistControl: "https://control.artists.amazon.com/V1/web",
    userManagement: "https://user.artists.amazon.com/V1/web",
    reporting: "https://reporting.artists.amazon.com/V1/web",
    catalog: "https://catalog.artists.amazon.com/V1/web",
    teamManagement: "https://team.artists.amazon.com/V1/web",
    inviteMangement:
        "https://invite.artists.amazon.com/V1/web/invitemanagement",
    outageStatus: "https://status.artists.amazon.com",
    merch: "https://merch.artists.amazon.com/V1/web",
    clientMetrics: "https://metrics.artists.amazon.com/web",
    oAuth: "https://auth.artists.amazon.com/V1/web",
};

const prototypeEndpoints: serviceEndpoints = {
    userManagement: "https://user.prototype.alps.music.amazon.dev/V1/web",
    reporting: "https://reporting.prototype.alps.music.amazon.dev/V1/web",
    catalog: "https://catalog.prototype.alps.music.amazon.dev/V1/web",
    teamManagement: "https://team.prototype.alps.music.amazon.dev/V1/web",
    inviteMangement:
        "https://invite.prototype.alps.music.amazon.dev/V1/invitemanagement/web",
    artistControl: "https://control.prototype.alps.music.amazon.dev/V1/web",
    outageStatus: "https://status.prototype.alps.music.amazon.dev/",
    merch: "https://merch.prototype.alps.music.amazon.dev/web",
    clientMetrics: "https://metrics.prototype.alps.music.amazon.dev/web",
    oAuth: "https://auth.prototype.alps.music.amazon.dev/V1/web",
};

// these are not usable cause they do not end with amazon.com
const betaEndpoints: serviceEndpoints = {
    artistControl: "https://93zfao7so3.execute-api.us-east-1.amazonaws.com/V1",
    userManagement: "https://jw5te2gbf4.execute-api.us-east-1.amazonaws.com/V1", // "https://v6s9fz46ke.execute-api.us-east-1.amazonaws.com/V1",
    reporting: "https://ycf1fg5ui3.execute-api.us-east-1.amazonaws.com/V1",
    catalog: "https://a05rv8h4ml.execute-api.us-east-1.amazonaws.com/V1",
    teamManagement: "https://jnv3xbalkl.execute-api.us-east-1.amazonaws.com/V1",
    inviteMangement:
        "https://invite.beta.alps.music.amazon.dev/V1/invitemanagement",
    outageStatus: "https://status.beta.alps.music.amazon.dev/",
    merch: "https://merch.beta.alps.music.amazon.dev",
    clientMetrics: "https://metrics.beta.alps.music.amazon.dev",
    oAuth: "https://auth.beta.alps.music.amazon.dev/V1/web",
};

export const getEndpoints = (): serviceEndpoints => {
    return prodEndpoints;
    // Need to figure out call to beta. All calls to beta fail on auth, as cookies don't get passed in.
    // isProd ? prodEndpoints : betaEndpoints; // Use beta endpoints when in debug build
};

// same with this case we are not going to be able to talk to the claims portal after wards.
const betaThirdPartyUrls: thirdPartyEndpoionts = {
    cdBabyUrl: (state: string, clientId: string) =>
        `https://auth.cdbaby.com/connect/authorize?
        client_id=${clientId}
        &response_type=code%20id_token
        &scope=openid%20profile%20cdbAPI%20offline_access
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/cdbaby&state=${state}&nonce=${state}
        &response_mode=form_post`
            .replace(/\s+/g, "")
            .trim(),
    distrokidUrl: (state: string, clientId: string) =>
        `https://distrokid.com//oauth2/authorize/?
        client_id=${clientId}
        &response_type=code
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/distrokid&state=${state}
        &scope=read-upc`
            .replace(/\s+/g, "")
            .trim(),
    tunecoreUrl: (state: string, clientId: string) =>
        `https://api.tunecore.com/oauth/authorize/?
        client_id=${clientId}
        &response_type=code
        &prompt=true
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/tunecore&state=${state}
        &response_type=code`
            .replace(/\s+/g, "")
            .trim(),
    tunecoreJPUrl: (state: string, clientId: string) =>
        `https://www.tunecore.co.jp/auth?
        client_id=${clientId}
        &response_type=code
        &prompt=true
        &redirect_uri=${encodeURIComponent(
            "https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/tunecorejapan"
        )}
        &state=${state}`
            .replace(/\s+/g, "")
            .trim(),
    instagramUrl: (state: string, clientId: string) =>
        `https://api.instagram.com/oauth/authorize?
        client_id=${clientId}
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/instagramV2
        &state=${state}
        &response_type=code
        &scope=user_profile`
            .replace(/\s+/g, "")
            .trim(),
    facebookUrl: (state: string, clientId: string) =>
        `https://www.facebook.com/v5.0/dialog/oauth?client_id=${clientId}
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/facebook
        &state=${state}
        &scope=pages_show_list,pages_read_engagement`
            .replace(/\s+/g, "")
            .trim(),
    twitterUrl: (state: string, clientId: string) =>
        `https://twitter.com/i/oauth2/authorize?response_type=code
        &client_id=${clientId}
        &redirect_uri=https://auth.beta.alps.music.amazon.dev/V1/api/linkaccount/twitter
        &scope=follows.read%20offline.access
        &state=${state}
        &code_challenge=challenge
        &code_challenge_method=plain`
            .replace(/\s+/g, "")
            .trim(),
    merchOnDemandUrl: () => `https://merch.amazon.com/dashboard`,
};

const prodThirdPartyUrls: thirdPartyEndpoionts = {
    cdBabyUrl: (state: string, clientId: string) =>
        `https://auth.cdbaby.com/connect/authorize?
        client_id=${clientId}
        &response_type=code%20id_token
        &scope=openid%20profile%20cdbAPI%20offline_access
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/cdbaby&state=${state}&nonce=${state}
        &response_mode=form_post`
            .replace(/\s+/g, "")
            .trim(),
    distrokidUrl: (state: string, clientId: string) =>
        `https://distrokid.com//oauth2/authorize/?
        client_id=${clientId}
        &response_type=code
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/distrokid&state=${state}
        &scope=read-upc`
            .replace(/\s+/g, "")
            .trim(),
    tunecoreUrl: (state: string, clientId: string) =>
        `https://api.tunecore.com/oauth/authorize/?
        client_id=${clientId}
        &response_type=code
        &prompt=true
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/tunecore&state=${state}
        &response_type=code`
            .replace(/\s+/g, "")
            .trim(),
    tunecoreJPUrl: (state: string, clientId: string) =>
        `https://www.tunecore.co.jp/auth?
        client_id=${clientId}
        &response_type=code
        &prompt=true
        &redirect_uri=${encodeURIComponent(
            "https://auth.artists.amazon.com/V1/api/linkaccount/tunecorejapan"
        )}
        &state=${state}`
            .replace(/\s+/g, "")
            .trim(),
    instagramUrl: (state: string, clientId: string) =>
        `https://api.instagram.com/oauth/authorize?
        client_id=${clientId}
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/instagramV2
        &state=${state}
        &response_type=code
        &scope=user_profile`
            .replace(/\s+/g, "")
            .trim(),
    facebookUrl: (state: string, clientId: string) =>
        `https://www.facebook.com/v5.0/dialog/oauth?client_id=${clientId}
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/facebook
        &state=${state}
        &scope=pages_show_list,pages_read_engagement`
            .replace(/\s+/g, "")
            .trim(),
    twitterUrl: (state: string, clientId: string) =>
        `https://twitter.com/i/oauth2/authorize?response_type=code
        &client_id=${clientId}
        &redirect_uri=https://auth.artists.amazon.com/V1/api/linkaccount/twitter
        &scope=tweet.read%20users.read%20follows.read%20offline.access
        &state=${state}
        &code_challenge=challenge
        &code_challenge_method=plain`
            .replace(/\s+/g, "")
            .trim(),
    merchOnDemandUrl: () => `https://merch.amazon.com/dashboard`,
};

export const getThirdPartyEndpoints = (): thirdPartyEndpoionts => {
    return prodThirdPartyUrls;
    // We need to figure out the endpoints for the beta stack (Backend).
    // isProd ? prodThirdPartyUrls : betaThirdPartyUrls; // Use beta endpoints when in debug build
};

// If there is a cookie called token, use that in the header, otherwise dont.
export const testHeaders = () => {
    const header = getCookieByKey("token");
    if (!header) {
        return null;
    }
    return {
        "x-amz-access-token": header,
    };
};

export function getCookieByKey(key: string) {
    return Cookies.get(key);
}

export function hasCookieForKey(key: string) {
    return !!Cookies.get(key);
}

// Function to clear all cookies from amazon.com to figure out if user is logged in
export function clearAmazonLogInCookies() {
    removeCookie(cookieStrings.ubidUS);
    removeCookie(cookieStrings.ubidJP);
    removeCookie(cookieStrings.sessionId);
    removeCookie(cookieStrings.sessionToken);
    removeCookie(cookieStrings.userId);
}

export function removeCookie(cookieString: cookieStrings) {
    Cookies.remove(cookieString);
}

export function clearPersistentState() {
    unsubscribe();
    localStorage.removeItem("state");
}

export function signOut(callback: (() => void) | undefined = undefined) {
    clearAmazonLogInCookies();
    localStorage.clear();
    clearStore();
    callback && callback();
    window.location.replace(signOutUrl(hasCookieForKey(cookieStrings.xJP)));
}

// needs to take in axios instead of importing to avoid breaking tests
export function setCsrfToken(axios: any, token: string) {
    axios.defaults.headers.post["x-csrf-token"] = token;
    axios.defaults.headers.delete["x-csrf-token"] = token;
    axios.defaults.headers.get["x-csrf-token"] = token;
    axios.defaults.headers.put["x-csrf-token"] = token;
}

export const getUrlParam = (param: string): string | null => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
};
